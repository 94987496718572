export const researcherPosition = [
  {
    value: 'None',
    label: 'None',
  },
  {
    value: 'Research Assistant',
    label: 'Research Assistant',
  },
  {
    value: 'Research Coordinator',
    label: 'Research Coordinator',
  },
  {
    value: 'Graduate Student',
    label: 'Graduate Student',
  },
  {
    value: 'Principal Investigator',
    label: 'Principal Investigator',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

export const columnsResearcher = [
  {
    header: 'Project Name',
    key: 'nick_name',
  },
  {
    header: 'id',
    key: 'id',
  },
  {
    header: 'Type',
    key: 'type',
  },
  {
    header: 'Expires',
    key: 'recruitment_end',
  },
  {
    header: 'Status ',
    key: 'status',
  },
];

export const columnsVolunteer = [
  {
    header: 'check',
    key: 'check',
  },
  {
    header: 'ID',
    key: 'volunteer_id',
  },
  {
    header: 'First Name',
    key: 'first_name',
  },
  {
    header: 'Last Name',
    key: 'last_name',
  },
  {
    header: 'Date of Birth',
    key: 'birth_date',
  },
  {
    header: 'Email',
    key: 'email',
  },
  {
    header: 'Phone',
    key: 'phone_number',
  },
  {
    header: 'Gender',
    key: 'gender',
  },
  {
    header: 'Follow-up Status',
    key: 'enrollment_status',
  },
];

export const columnsSiteVolunteer = [
  {
    header: 'ID',
    key: 'id',
  },
  {
    header: 'Date Joined',
    key: 'date_joined',
  },
  {
    header: 'First Name',
    key: 'first_name',
  },
  {
    header: 'Last Name',
    key: 'last_name',
  },
  {
    header: 'Date of birth',
    key: 'birth_date',
  },
  {
    header: 'Email',
    key: 'email',
  },
  {
    header: 'Phone',
    key: 'phone_number',
  },
];

export const studyForm = {
  title: 'Add a Research Study',
  description: [
    'Use this form to publish your research study online.',
    'Research study details must be approved by both the REACH BC Office and the appropriate Research Ethics Board.',
  ],
  downloads: [
    {
      boldText: 'Not ready to fill out this entire form? ',
      endText: 'Save as Draft, and take your time to complete it',
      linkText: '',
      downloadUrl: '',
    },
    {
      boldText: 'Have a closed/completed Research Study?',
      startText: ' See our ',
      endText: ' on how to complete the form.',
      linkText: 'guide',
      downloadUrl: 'https://assets.ctfassets.net/rbx11ml43wqx/7znyH95kebm2Ggu7oPBS5Q/5f2db40f7f7e145d889bf243fd6570be/Document_212_-_Closed_Research_Study_Guide.pdf',
    },
  ],
};

export const ppoForm = {
  title: 'Add a Patient Partner Research Opportunity',
  description: [
    'Use this form to publish your research study online.',
    'Research study details must be approved by both the REACH BC Office and the appropriate Research Ethics Board.',
  ],
  downloads: [
    {
      startText: 'Please review the ',
      endText: ' to determine if you are ready to engage patients as partners.',
      linkText: 'Patient Partner Engagement Readiness Checklist',
      downloadUrl: 'https://assets.ctfassets.net/rbx11ml43wqx/4Rd7LmxBOQsreHNI4vxRSc/3ef0cb0e4742bc862c4e36d4103618f2/Document_209_-_POR_Readiness_Checklist_20200707.pdf',
    },
    {
      boldText: 'Not ready to fill out this entire form? ',
      endText: 'Save as Draft to take your time to take your time.',
      linkText: '',
      downloadUrl: '',
    },
  ],
};

export const studyResultForm = {
  title: 'Study Results Form',
  description: [
    'Use this form to publish the results of your completed research study on REACH BC. If your project is still in progress, you can share a summary of results to date.',
    'If you posted a patient partner opportunity and a research study on REACH BC for the same project, you only need to complete this form below.',
  ],
};

export const ppoResultForm = {
  title: 'Patient Partner Research Opportunity Results Form',
  description: [
    'Use this form to publish the results of your completed PPO research on REACH BC. If your project is still in progress, you can share a summary of results to date.',
  ],
};

export const regionalCentre: { [key: string]: string } = {
  fraser: 'Fraser Centre',
  vancouver: 'Vancouver Centre',
  northern: 'Northern Centre',
  interior: 'Interior Centre',
  vancouver_island: 'Vancouver Island Centre',
};

export const TypeOfResearchStudy = [
  { value: 'Biomedical', label: 'Biomedical' },
  { value: 'Clinical', label: 'Clinical' },
  { value: 'Health systems and services', label: 'Health systems and services' },
  { value: 'Social, cultural, environmental and population health', label: 'Social, cultural, environmental and population health' },
  { value: 'Other', label: 'Other' },
];

export const TypeOfPpo = [
  { value: 'Advisory Boards and Committees', label: 'Advisory Boards and Committees' },
  { value: 'Patient-Centered Outcomes Research (PCOR)', label: 'Patient-Centered Outcomes Research (PCOR)' },
  { value: 'Focus Groups and Interviews', label: 'Focus Groups and Interviews' },
  { value: 'Community-Based Participatory Research (CBPR)', label: 'Community-Based Participatory Research (CBPR)' },
  { value: 'Online Patient Communities', label: 'Online Patient Communities' },
  { value: 'Other', label: 'Other' },
];

export const fundingAgencyOptions = [
  {
    value: 'For-profit company (e.g. industry sponsor)',
    label: 'For-profit company (e.g. industry sponsor)',
  },
  {
    value: 'Michael Smith Health Research BC',
    label: 'Michael Smith Health Research BC',
  },
  {
    value: 'Government or non-profit foundation (e.g. Canadian Institutes of Health Research)',
    label: 'Government or non-profit foundation (e.g. Canadian Institutes of Health Research)',
  },
  { value: 'Not funded', label: 'Not funded' },
];

export const gendersEligible = [
  'Woman',
  'Man',
  'Non-binary',
  'Trans woman',
  'Trans man',
  'Two-spirit',
];

export const sexEligible = [
  'Female',
  'Male',
];

export const levelsOfEngagements = [
  {
    name: 'Inform',
    label:
      'To provide the public with balanced and objective information to assist them in understanding the problems, alternatives and/or solutions.',
  },
  {
    name: 'Consult',
    label: 'To obtain public feedback on analysis, alternatives and/or decision.',
  },
  {
    name: 'Involve',
    label:
      'To work directly with the public throughout the process to ensure that public concerns and aspirations are consistently understood and considered.',
  },
  {
    name: 'Collaborate',
    label:
      'To partner with the public in each aspect of the decision including the development of alternatives and the identification of the preferred solution.',
  },
  {
    name: 'Empower',
    label: 'To place final decision-making in the hands of the public.',
  },
];

export const projectStatus: { [key: string]: string } = Object.freeze({
  pending_approval: 'Pending Approval',
  approved: 'Currently Recruiting',
  closed_recruitment: 'Closed Recruitment',
  on_hold: 'On Hold',
  completed_project: 'Project Completed',
  draft: 'Draft',
  reb_review: 'REB Review',
  in_progress: 'In Progress',
});

export const resultStatus: { [key: string]: string } = Object.freeze({
  pending_approval: 'Results Pending Approval',
  approved: 'Results Approved',
});

export const formStatus: { [key: string]: string } = Object.freeze({
  pending_approval: 'Your research is under review',
  approved: 'Research Project Approved',
  closed_recruitment: 'Closed Recruitment',
  on_hold: 'On Hold',
  completed_project: 'Completed Project',
  draft: 'Draft',
  reb_review: 'Your research is under REB review',
});

export const resultFormStatus: { [key: string]: string } = Object.freeze({
  pending_approval: 'Your result is under review',
  approved: 'Your result are Approved',
});

export const formStatusIcons: { [key: string]: string } = Object.freeze({
  pending_approval: 'hourglass',
  approved: 'check-circle',
  closed_recruitment: '',
  on_hold: '',
  completed_project: '',
  draft: '',
  reb_review: 'hourglass',
});

export const ProjectStatusLabels = Object.freeze({
  approved: 'Currently Recruiting',
  closed_recruitment: 'Recruitment Closed',
  completed_project: 'Completed Project',
});

export const EngagementsText: { [key: string]: string } = {
  inform:
    'To provide the public with balanced and objective information to assist them in understanding the problems, alternatives and/or solutions.',
  consult: 'To obtain public feedback on analysis, alternatives and/or decision.',
  involve:
    'To work directly with the public throughout the process to ensure that public concerns and aspirations are consistently understood and considered.',
  collaborate:
    'To partner with the public in each aspect of the decision including the development of alternatives and the identification of the preferred solution.',
  empower: 'To place final decision-making in the hands of the public.',
};

export const ProjectStatusOptions = [
  {
    value: 'approved',
    discription: 'Listed in the public directory, will be matched to volunteers',
  },
  {
    value: 'on_hold',
    discription: 'Hidden from public directory, not ready to recruit',
  },
  {
    value: 'closed_recruitment',
    discription: 'Listed in public directory, will not be matched to volunteers. For sharing of interim results',
  },
  {
    value: 'completed_project',
    discription: 'Listed in public directory, will not be matched to volunteers. For sharing of final results',
  },
];

export const enrollmentStatusOptions = [
  { value: 'not_contacted', label: 'Have not been contacted' },
  { value: 'follow_up', label: 'Follow up in progress' },
  { value: 'enrolled', label: 'Enrolled' },
  { value: 'participation_unknown', label: 'Participation unknown - Study link sent' },
  { value: 'ineligible', label: 'Not Enrolled - Ineligible' },
  { value: 'not_interested', label: 'Not Enrolled - Not interested' },
  { value: 'too_busy', label: 'Not Enrolled - Too busy' },
  { value: 'recruitment_closed', label: 'Not Enrolled - Recruitment closed' },
  { value: 'contact_attempt', label: 'Not Enrolled - Attempted contact, no response' },
  { value: 'already_enrolled', label: 'Not Enrolled - Already enrolled in another research study' },
  { value: 'other', label: 'Not Enrolled - Other' },
];

export const errorFieldNames: { [key: string]: string } = {
  commitment: 'estimated commitment',
  orientation_provided: 'orientation provided',
  level_of_engagement: 'level of engagement',
  description: "what's involved",
  purpose: 'purpose',
  postal_code: 'postal code',
  street_address: 'street address',
  pi_id: 'Principal Investigator',
  additional_eligibility: 'additional eligibility criteria',
  health_categories: 'health research categories',
  contact1_name: 'contact 1 name',
  contact1_email: 'contact 1 email',
  contact1_phone: 'contact 1 phone',
  contact1_position: 'contact 1 position',
  contact1_phone_extension: 'contact 1 phone extension',
  contact2_name: 'contact 2 name',
  contact2_email: 'contact 2 email',
  contact2_phone: 'contact 2 phone',
  contact2_position: 'contact 2 position',
  contact2_phone_extension: 'contact 2 phone extension',
  title: 'title',
  lay_title: 'lay title',
  nick_name: 'headline',
  funding_agency: 'funding agency',
  recruitment_end: 'recruitment end',
  health_authority_region: 'health authority region',
  min_age: 'min age requirement',
  max_age: 'max age requirement',
  study_type: 'study type',
  desired_status: 'project status',
  result_summary: 'Summary of results',
  study_limitations: 'Study limitations',
  healthAuthorityRegions: 'Health authority regions',
  healthCategories: 'Health categories',
  patient_partners_engagement_method: 'Patient partners engagement method',
  patient_partners_engagement_number: 'patient partners engagement number',
};

export const StatusRecruitmentEndUpdate = ['closed_recruitment', 'completed_project'];
