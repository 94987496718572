import { checkForURL } from './checkForURL';
import { stringURlReplacement } from './stingUrlReplacement';

export const paraphFormater = (paraph: string) => {
  const formatParaphResults = paraph.split('\r\n') || [];

  return formatParaphResults.map((para: string) => {
    if (checkForURL(para)) {
      const detailsArray = stringURlReplacement(para);
      return detailsArray;
    }
    return para;
  });
};
