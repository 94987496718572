import React from 'react';
import AppWrapper from '../../../components/AppWrapper';
import { HeroBanner } from '../../../components/ProjectForm/components';
import { STAND_ALONE_RESULTS } from './const';
import { UserNotice } from '../../../components';
import * as Styled from './styles';
import { ResultMainForm, ResultSideMenu } from '../components';
import { useCreateStandAloneResults } from './useCreateStandAloneResults';

const CreateStandAloneResult = () => {
  const hook = useCreateStandAloneResults();

  return (
    <AppWrapper>
      <HeroBanner
        generalInfo={STAND_ALONE_RESULTS.HEADER}
        handleNavigate={hook.handleNavigate}
      />
      <UserNotice
        title={STAND_ALONE_RESULTS.INFO_BOX.TITLE}
        description={STAND_ALONE_RESULTS.INFO_BOX.DESCRIPTION}
        link={STAND_ALONE_RESULTS.INFO_BOX.LINK}
        secondaryLink={STAND_ALONE_RESULTS.INFO_BOX.SECONDARY_LINK}
      />

      <Styled.SpiltSectionStyled>
        <ResultMainForm
          resultInfo={hook.resultInfo}
          addInputHandler={hook.addInputHandler}
          teamMembers={hook.teamMembers}
          updateResultInfoHandler={hook.updateResultInfoHandler}
          changeAddedInputHandler={hook.changeAddedInputHandler}
          removeInputHandler={hook.removeInputHandler}
          collaboratingOrganizations={hook.collaboratingOrganizations}
          selectedRegionalCenters={hook.selectedRegionalCenters}
          categoriesMap={hook.healthCategories}
          setHealthCategoriesHandler={hook.setHealthCategoriesHandler}
          selectedHealthCategories={hook.selectedHealthCategories}
          forceChange={hook.forceChange}
          healthAuthorityRegions={hook.healthAuthorityRegions}
          checkboxInputHandler={hook.checkboxInputHandler}
          selectedHealthAuthorityRegions={hook.selectedHealthAuthorityRegions}
          fileHandler={hook.fileHandler}
          removeFileHandler={hook.removeFileHandler}
          currentFile={hook.resourceFile}
          checkForReseacherAccount={hook.checkForReseacherAccount}
          hospitals={hook.hospitals}
          errors={hook.errors}
        />
        <Styled.StickyDivStyled>
          <ResultSideMenu
            status={hook.resultInfo.status}
            submitHandler={hook.submitHandler}
            isSubmitting={hook.isSubmiting}
            isNew
            errors={hook.errors}
          />
        </Styled.StickyDivStyled>
      </Styled.SpiltSectionStyled>
    </AppWrapper>
  );
};

export default CreateStandAloneResult;
