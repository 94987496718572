import styled from 'styled-components';
import toRem from '../../../utils/toRem';
import { fonts } from '../../../styles/theme';

export const EmptyStateStyled = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: ${toRem(24)};
  ${fonts.font1.H16};
`;

export const IconWrapper = styled.div`
  max-width: 140px;
`;

export const BodyWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const LinkTextStyled = styled.p`
  ${fonts.font1.H14B};
`;

export const LinkDivStyled = styled.div`
  display: flex;
  align-items: center;
  gap: ${toRem(10)};
  margin-top: ${toRem(8)};
  ${fonts.font1.H14};
`;
