import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';
import toRem from '../../utils/toRem';
import { TextP } from '../Text';

type TextWrapperProps = {
  readonly marginTheme?: string
}

export const HeroWrapper = styled.section`
  background: ${colours.colour23};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${fonts.font1.H14};
  padding: 2.688rem;
  margin-bottom: 0.875rem;
  min-height: 302px;
`;

export const TextWrapper = styled.div<TextWrapperProps>`
  word-break: break-word;
  overflow-wrap: break-word;
  margin: ${({ marginTheme }) => {
    switch (marginTheme) {
      case 'studyType':
        return '0.938rem 0';
      case 'hero':
        return '0.625rem 0';
      case 'sm':
        return '0 0 0.625rem';
      default:
        return '0 0 1rem';
    }
  }};
`;

export const TagStyled = styled.div`
  -webkit-font-smoothing: antialiased;
  background: ${colours.colour21};
  border: 2px solid ${colours.colour26};
  border-radius: 4px;
  color: ${colours.colour5};
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  padding: 6px 10px;
`;

export const LinkWrapper = styled.div`
  background: ${colours.colour23};
  width: 100%;
  padding: ${toRem(15)} 0 0 ${toRem(40)};
  display: flex;
  flex-direction: row;
`;

export const LinkContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${TextP} {
    margin-left: ${toRem(14)};
  }
`;

export const CompletedWrapper = styled.div`
  margin-top: ${toRem(24)};
`;
