import React from 'react';
import { useLocation } from 'react-router-dom';
import { LinkComponent } from '../../components/Link';
import { TextH1, TextP } from '../../components/Text';
import { HeaderWrapper, SuccessWrapper } from '../../styles/SuccessStyles';
import { fonts } from '../../styles/theme';

interface propState {
  id: string;
  type: string
  draftType?: string
  resultType?: string
}

const Success = () => {
  const location = useLocation();

  const {
    id, type, draftType, resultType,
  } = location.state as propState;

  const messages: { [key: string]: { title: string, detail: string, path: string } } = {
    study: {
      title: 'Submitted for Review',
      detail: 'Thank you for your submission. The REACH BC Office will review your research opportunity and contact you regarding next steps. This may take up to 3 business days.',
      path: `/project/study/${id}`,
    },
    studyStatus: {
      title: 'Save Successful',
      detail: 'Your changes have been saved.',
      path: `/project/study/${id}`,
    },
    ppo: {
      title: 'Submitted for Review',
      detail: 'Thank you for your submission. The REACH BC Office will review your research opportunity and contact you regarding next steps. This may take up to 3 business days.',
      path: `/project/ppo/${id}`,
    },
    ppoStatus: {
      title: 'Save Successful',
      detail: 'Your changes have been saved.',
      path: `/project/ppo/${id}`,
    },
    draft: {
      title: 'Saved as Draft',
      detail: '',
      path: `/project/${draftType}/${id}`,
    },
    result: {
      title: 'Submitted for Review',
      detail: 'Thank you for your submission. The REACH BC Office will review your results.',
      path: `/project/${resultType}/${id}?results`,
    },
    standAloneResult: {
      title: 'Submitted for Review',
      detail: 'Thank you for your submission. The REACH BC Office will review your results.',
      path: `/result/standalone/${id}`,
    },
  };

  return (
    <SuccessWrapper className="ui center aligned grid transition fade in">
      <div className=" fourteen wide mobile six wide tablet four wide computer column">
        <HeaderWrapper className="ui basic center aligned segment">
          <TextH1 font={fonts.font1.H26B}>{messages[type]?.title ? messages[type].title : 'Success'}</TextH1>
        </HeaderWrapper>
        {messages[type]?.detail
          && (
            <TextP font={fonts.font1.H14} lineHeight="1.33rem" textAlgin="center">
              {messages[type]?.detail}
            </TextP>
          )}
        <HeaderWrapper>
          <LinkComponent
            theme="outline"
            url={messages[type]?.path}
          >
            <div>
              {(!draftType && resultType !== 'standAloneResult') ? <TextP font={fonts.font1.H14} textAlgin="center">View my Project</TextP> : <TextP font={fonts.font1.H14} textAlgin="center">Continue</TextP>}
            </div>
          </LinkComponent>
        </HeaderWrapper>
      </div>
    </SuccessWrapper>
  );
};

export default Success;
