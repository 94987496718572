import styled from 'styled-components';
import { colours, fonts } from '../../../styles/theme';
import toRem from '../../../utils/toRem';

export const ModalTitleStyled = styled.div`
  ${fonts.font1.H24B};
  padding: ${toRem(16)} ${toRem(8)} ${toRem(24)};
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${colours.colour41};
`;

export const ModalBodyStyled = styled.div`
  padding: ${toRem(32)} ${toRem(8)} ${toRem(8)}; 
  width: 736px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${toRem(40)};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InputsLabelStyled = styled.p`
  ${fonts.font1.H20B};
`;

export const RadioWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 1rem 0;
`;

export const LabelStyled = styled.label`
  margin-left: 0.875rem;
  ${fonts.font1.H16};
  cursor: pointer;
`;

export const LabelTitleStyled = styled.div`
  margin-bottom: 0.5rem;
`;

export const InputWrapper = styled.div`
  margin-bottom: ${toRem(24)};
`;

export const LinkButtonWrapper = styled.div``;
