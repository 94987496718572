import React from 'react';
import { ResultSideMenuPropTypes } from './types';
import { RESULT_SIDE_MENU, RESULT_STATUS, STATUS_ICON } from './const';
import StatusTag from '../../../../components/StatusTag';
import Button from '../../../../components/Button';
import * as Styled from './styles';
import { LinkComponent } from '../../../../components';
import { errorFieldNames } from '../../../../utils';

export const ResultSideMenu = ({
  status,
  submitHandler,
  isSubmitting,
  isNew = false,
  errors,
}: ResultSideMenuPropTypes) => (
  <Styled.SideMenuWrapper>
    {!isNew && (
      <Styled.StatusWrapper>
        <StatusTag
          label={RESULT_STATUS[status]}
          theme={status}
          icon={STATUS_ICON[status]}
          isRound
          useIcon={STATUS_ICON[status]}
        />
      </Styled.StatusWrapper>
    )}

    <Button
      id="result_submit"
      onClick={submitHandler}
      theme="primary"
      disabled={isSubmitting}
      buttonWidth="100%"
    >
      {RESULT_SIDE_MENU.BUTTON}
    </Button>
    {errors && (
      <div>
        {Object.keys(errors).length > 0
          && (
            <Styled.ErrorWrapper>
              <Styled.ErrorStyled>
                <Styled.ErrorTitleStyled>
                  {RESULT_SIDE_MENU.ERROR_MESSAGES.DESCRIPTION_START}
                  {Object.keys(errors).length}
                  {RESULT_SIDE_MENU.ERROR_MESSAGES.DESCRIPTION_FINISH}
                </Styled.ErrorTitleStyled>
              </Styled.ErrorStyled>
              {Object.entries(errors).map((error) => (
                <Styled.ErrorStyled key={error[0]}>
                  <LinkComponent url={`#${error[0]}`}>
                    {errorFieldNames[error[0]] ? errorFieldNames[error[0]] : error[0]}
                  </LinkComponent>
                  <p>{error[1]}</p>
                </Styled.ErrorStyled>
              ))}
            </Styled.ErrorWrapper>
          )}
      </div>
    )}
  </Styled.SideMenuWrapper>
);
