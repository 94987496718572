import React from 'react';
import FormSection from '../../../../../components/FormSelection';
import { RESULTS_MAIN_FORM } from '../const';
import FormSubtitle from '../../../../../components/FormSubtitle';
import { Checkbox, TextInput } from '../../../../../components';
import * as Styled from '../styles';
import { TextArea } from '../../../../../components/TextArea.tsx';
import { StudyCompletedSectionPropTypes } from '../types';
import { DropdownSelect } from '../../../../../components/DropdownSelect';
import { NumberInput } from '../../../../../components/NumberInput';

export const StudyCompletedSection = ({
  resultInfo,
  hospitals,
  updateResultInfoHandler,
  checkboxInputHandler,
  errors,
}: StudyCompletedSectionPropTypes) => (
  <FormSection noHeader>
    <Styled.FormChildrenWrapper>
      <TextArea
        id={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.SUMMARY.NAME}
        label={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.SUMMARY.LABEL}
        description={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.SUMMARY.DESCRIPTION}
        textValue={resultInfo.result_summary || ''}
        setTextValue={(value) => updateResultInfoHandler(RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.SUMMARY.NAME, value)}
        theme="form"
        maxLength={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.SUMMARY.COUNT}
        error={errors?.result_summary}
      />

      {(resultInfo.is_por || resultInfo.is_ppo) && (
        <>
          <NumberInput
            label={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.ENGAGED.LABEL}
            description={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.ENGAGED.DESCRIPTION}
            numberValue={Number(resultInfo.patient_partners_engagement_number)}
            setNumberValue={(value) => updateResultInfoHandler(RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.ENGAGED.NAME, value)}
            theme="form"
            padding="md"
            error={errors?.patient_partners_engagement_number}
          />
          <TextArea
            label={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.ENGAGEMENT_METHOD.LABEL}
            description={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.ENGAGEMENT_METHOD.DESCRIPTION}
            description2={[RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.ENGAGEMENT_METHOD.DESCRIPTION_SECONDARY]}
            textValue={resultInfo.patient_partners_engagement_method || ''}
            setTextValue={(value) => updateResultInfoHandler(RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.ENGAGEMENT_METHOD.NAME, value)}
            maxLength={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.ENGAGEMENT_METHOD.COUNT}
            theme="form"
            error={errors?.patient_partners_engagement_method}
          />
        </>
      )}

      <TextArea
        id={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.LIMITATIONS.NAME}
        label={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.LIMITATIONS.LABEL}
        description={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.LIMITATIONS.DESCRIPTION}
        description2={[RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.LIMITATIONS.DESCRIPTION_SECONDARY]}
        textValue={resultInfo.study_limitations || ''}
        setTextValue={(value) => updateResultInfoHandler(RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.LIMITATIONS.NAME, value)}
        maxLength={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.LIMITATIONS.COUNT}
        theme="form"
        isRequired
        error={errors?.study_limitations}
      />

      <div>
        <FormSubtitle
          subtitle={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.ONLINE.TITLE}
          margin="xsmall"
        />
        <Styled.FormDescriptionStyles>
          {RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.ONLINE.DESCRIPTION}
        </Styled.FormDescriptionStyles>
        <Checkbox
          label={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.ONLINE.LABEL}
          isChecked={resultInfo.is_online || false}
          onChange={(checked) => checkboxInputHandler(RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.ONLINE.NAME, checked, RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.ONLINE.NAME)}
          textTransform="none"
        />
      </div>
      {!resultInfo.is_online && (
        <>
          <DropdownSelect
            id={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.HOSPITAL.NAME}
            label={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.HOSPITAL.LABEL}
            selected={
              hospitals.find((hospital) => parseInt(hospital.value, 10) === parseInt(`${resultInfo.hospital_id}`, 10))
              || { label: '', value: '' }
            }
            onChange={(option: { value: string }) => updateResultInfoHandler(
              RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.HOSPITAL.NAME,
              option.value,
            )}
            options={hospitals}
            placeholder={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.HOSPITAL.PLACEHOLDER}
            theme="form"
            error={errors?.hospital_id}
          />

          <TextInput
            id={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.BUILDING.NAME}
            label={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.BUILDING.LABEL}
            description={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.BUILDING.DESCRIPTION}
            setTextValue={(value) => updateResultInfoHandler(RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.BUILDING.NAME, value)}
            textValue={resultInfo.building}
            theme="form"
            isRequired={false}
            error={errors?.building}
          />

          <TextInput
            id={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.DEPARTMENT.NAME}
            label={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.DEPARTMENT.LABEL}
            description={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.DEPARTMENT.DESCRIPTION}
            setTextValue={(value) => updateResultInfoHandler(RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.DEPARTMENT.NAME, value)}
            textValue={resultInfo.department}
            theme="form"
            isRequired={false}
            error={errors?.department}
          />
          <TextInput
            id={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.STREET.NAME}
            label={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.STREET.LABEL}
            description={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.STREET.DESCRIPTION}
            setTextValue={(value) => updateResultInfoHandler(RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.STREET.NAME, value)}
            textValue={resultInfo.street}
            theme="form"
            error={errors?.street}
          />
          <TextInput
            id={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.CITY.NAME}
            label={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.CITY.LABEL}
            description={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.CITY.DESCRIPTION}
            setTextValue={(value) => updateResultInfoHandler(RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.CITY.NAME, value)}
            textValue={resultInfo.city}
            theme="form"
            error={errors?.city}
          />
          <TextInput
            id={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.POSTAL_CODE.NAME}
            label={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.POSTAL_CODE.LABEL}
            description={RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.POSTAL_CODE.DESCRIPTION}
            setTextValue={(value) => updateResultInfoHandler(RESULTS_MAIN_FORM.STUDY_COMPLETED.INPUTS.POSTAL_CODE.NAME, value)}
            textValue={resultInfo.postal_code}
            theme="form"
            error={errors?.postal_code}
          />
        </>
      )}
    </Styled.FormChildrenWrapper>
  </FormSection>
);
