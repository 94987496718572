import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useAPI, useScrollTo, useUser } from '../../../hooks';
import { STAND_ALONE_RESULT_CONST } from './const';
import { SOURCE_TYPES } from '../../../utils';

export const useDetailStandAloneResult = () => {
  const params: any = useParams();
  const location: any = useLocation();

  const { getResultStandAlone } = useAPI();

  const { getUserInfo, user } = useUser();
  const [_, setSource] = useState();

  const [isLoading, setIsLoading] = useState(true);
  const [resultInfo, setResultInfo] = useState<IStandAloneResultDetail>();
  const [currentTab, setCurrentTab] = useState(STAND_ALONE_RESULT_CONST.TABS[0].URL);
  const [tabs, setTabs] = useState([STAND_ALONE_RESULT_CONST.TABS[0]]);

  const scrollHook = useScrollTo(STAND_ALONE_RESULT_CONST.TABS[0].URL, isLoading, currentTab);

  const setCurrentTabHandler = (url: string) => {
    setCurrentTab(url);
    scrollHook.scrollActionHandler(url);
  };

  const setupTabsHandler = (result: IStandAloneResultDetail) => {
    let needTabs = STAND_ALONE_RESULT_CONST.TABS;
    if (!result.is_por && !result.is_ppo) needTabs = needTabs.filter((tab) => tab.URL !== STAND_ALONE_RESULT_CONST.TAB_ID.ENGAGEMENT);

    if (!result.study_limitations) needTabs = needTabs.filter((tab) => tab.URL !== STAND_ALONE_RESULT_CONST.TAB_ID.LIMITATIONS);

    if (
      !result.resource_file_name
      && !result.resource_website_name
      && !result.resource_website_url
    ) needTabs = needTabs.filter((tab) => tab.URL !== STAND_ALONE_RESULT_CONST.TAB_ID.RESOURCES);

    setTabs(needTabs);
  };

  const getResults = async () => {
    const { id } = params;
    const sourceValue = location.state?.source || SOURCE_TYPES.APP;
    setSource(sourceValue);

    if (!id || id === undefined) throw new Error('Missing info');

    const { result } = await getResultStandAlone(id, sourceValue);

    setupTabsHandler(result);
    setResultInfo(result);
    setIsLoading(false);
  };

  useEffect(() => {
    getUserInfo();
    getResults();
  }, []);

  return {
    user,
    resultInfo,

    tabs,
    currentTab,
    setCurrentTabHandler,
    scrollHook,
  };
};
