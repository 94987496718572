import environment from '../../../config/environment';

export const RESEARCHER_DASHBOARD_HERO = {
  USER_MESSAGE: 'Welcome',
  LINK: {
    LABEL: 'Researcher Help Centre',
    URL: `${environment.app.websiteURL}/researcher-resources`,
  },
  Cards: {
    STUDIES: {
      TITLE: 'Research Study',
      DESCRIPTION: 'Create a study or trial listing to be matched with potential participants for health research studies recruiting participants (patients or healthy controls).',
      DESCRIPTION_SECONDARY: {
        START: 'Note that research studies require proof of ',
        BOLDED: 'Research Ethics Board (REB)',
        END: ' approval.',
      },
      LINK: {
        URL: '/study/new',
        LABEL: 'Add',
      },
    },
    PPO: {
      TITLE: 'Patient Partner',
      TITLE_SECOND_LINE: 'Research Opportunity',
      DESCRIPTION: 'Create a patient partner opportunity if you want to recruit patient partners to identify and prioritize research questions, design studies, develop grant proposals, prepare for the execution of a study, analyze and interpret data, or translate or disseminate knowledge',
      LINK: {
        URL: '/ppo/new',
        LABEL: 'Add',
      },
    },
    RESULTS: {
      TITLE: 'Study Result',
      DESCRIPTION: 'Disseminate your study results to study participants, patient partners, knowledge users and the general public.',
      LIST: [
        'Post interim or final results of your study',
        'Share your publications or knowledge mobilization products',
      ],
      DESCRIPTION_SECONDARY: {
        START: 'You can post even if you did not use ',
        BOLDED: 'REACH BC',
        END: ' for recruitment.',
      },
      BUTTON: {
        LABEL: 'Add',
      },
    },
  },
  BYPASS_MESSAGE: {
    TITLE: 'Attention Researchers:',
    DESCRIPTION:
      [
        'Due to technical issues with our SMS provider, Multi-factor authentication (MFA) for researcher logins is temporarily bypassed. Rest assured your account security remains our top priority. While you may still receive verification codes on your phone, please ignore them until further notice.',
      ],
  },
};
