import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';
import toRem from '../../utils/toRem';

type RowStyledProps = {
  readonly hoverAction?: boolean
  readonly hightLighted?: boolean
  readonly selected?: boolean
}

type HeaderCellStyledProps = {
  readonly type: string;
  readonly tableType?: string;
}

type CellStyledProps = {
  readonly type?: string;
  readonly tableType?: string;
}

export const TableScroller = styled.div`
  overflow-x: auto;
  width: 100%;
`;

export const TableStyled = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid transparent;
`;

export const HeaderRowStyled = styled.tr`
  border-bottom: 1px solid ${colours.colour41};
  text-align: left;
`;

export const HeaderCellStyled = styled.th<HeaderCellStyledProps>`
  padding: ${toRem(12)} ${toRem(8)} ${toRem(20)};
  overflow: hidden;

  width: ${({ type }) => {
    switch (type) {
      case 'type':
        return '60px';
      case 'recruitment_end':
        return '100px';
      case 'status':
        return '163px';
      case 'id':
        return '50px';
      case 'gender':
        return '100px';
      case 'enrollment_status':
        return '250px';
      default:
        return '100%';
    }
  }};

  border-bottom: ${({ tableType }) => (tableType === 'volunteer_table' ? '0.063rem solid black' : '')};
  color:  ${colours.colour6};
  ${fonts.font1.H12B}
  line-height: ${toRem(20)};

  div {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      margin-left: ${toRem(8)};
    }
  }

  button {
    text-transform: capitalize;
    align-items: center;
  }

  @media (min-width: 750px) {
  min-width: ${({ type }) => {
    switch (type) {
      case 'type':
        return '140px';
      case 'recruitment_end':
        return '140px';
      case 'date_joined':
        return '140px';
      case 'first_name':
        return '120px';
      case 'last_name':
        return '120px';
      case 'birth_date':
        return '140px';
      case 'status':
        return '165px';
      case 'id':
        return '120px';
      case 'enrollment_status':
        return '265px';
      case 'gender':
        return '100px';
      case 'email':
        return '235px';
      default:
        return '100%';
    }
  }};

  max-width: ${({ type }) => {
    switch (type) {
      case 'type':
        return '140px';
      case 'recruitment_end':
        return '140px';
      case 'date_joined':
        return '140px';
      case 'first_name':
        return '120px';
      case 'last_name':
        return '120px';
      case 'birth_date':
        return '140px';
      case 'status':
        return '165px';
      case 'id':
        return '120px';
      case 'gender':
        return '100px';
      case 'email':
        return '235px';
      default:
        return '100%';
    }
  }};
  }
`;

export const RowStyled = styled.tr<RowStyledProps>`
  border-bottom: 1px solid ${colours.colour41};
  background: ${({ hightLighted, selected }) => {
    if (hightLighted && !selected) return colours.colour50;
    if (selected) return colours.colour19;
  }};

  &:hover {
    background: ${({ hoverAction }) => (hoverAction ? colours.colour19 : '')};
  }
`;

export const CellStyled = styled.td<CellStyledProps>`
  color: ${colours.colour6};
  padding: ${({ tableType }) => (tableType === 'volunteer_table' ? '1.5rem 0.5rem' : '0.5rem')};
  div {
    ${({ type }) => (type === 'nick_name' ? fonts.font1.H14B : fonts.font1.H14)};
  }
`;

export const TableFooterStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 1rem 0 0;
`;

export const NumberInputWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 4px;
  min-width: 30%;
  margin: 0 1rem;
  flex-wrap: wrap;
`;
