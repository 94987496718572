import styled from 'styled-components';
import toRem from '../../../../../utils/toRem';
import { colours, fonts } from '../../../../../styles/theme';

type SideBarSectionStyledPropTypes = {
  readonly theme?: 'secondary'
  readonly size?: 'sm'
}

export const SideBarStyled = styled.section`
  padding: 0 ${toRem(14)} ${toRem(14)};
  display: flex;
  flex-direction: column;
  gap: ${toRem(40)};

  @media (min-width: 768px) {
    width: 25%;
    min-width: ${toRem(380)};
    padding: 0;    
  }
`;

export const SideBarSectionStyled = styled.div<SideBarSectionStyledPropTypes>`
  display: flex;
  padding: ${toRem(32)};
  border-radius: 16px;
  flex-direction: column;
  gap: ${({ size }) => (size === 'sm' ? toRem(8) : toRem(24))};
  background: ${({ theme }) => (theme === 'secondary' ? colours.colour50 : colours.colour4)};
  border: 1px solid ${({ theme }) => (theme === 'secondary' ? colours.colour28 : colours.colour39)};
  word-break: break-word;
  overflow-wrap: break-word;
`;

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(8)};
  margin-top: ${toRem(16)};
`;

export const SideBarTitleStyled = styled.h3`
  ${fonts.font1.H20B};
  margin-bottom: ${toRem(14)};
`;

export const AddressStyled = styled.p`
  text-transform: capitalize;
`;

export const UpperCaseSpan = styled.span`
  text-transform: uppercase;
`;
