import React, { useEffect } from 'react';
import {
  BrowserRouter, Routes, Route, Navigate,
} from 'react-router-dom';

import P404 from './pages/404';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import TwoFactorAuth from './pages/TwoFactorAuth';
import EmailVerification from './pages/EmailVerification';
import ForgotPassword from './pages/ForgotPassword';
import ResearcherCreate from './pages/ResearcherCreate';
import VolunteerCreate from './pages/VolunteerCreate';
import ProtectedRoute from './components/ProtectedRoute';
import StudyNew from './pages/Study/New';
import StudyEdit from './pages/Study/Edit';
import PPONew from './pages/PPO/New';
import Success from './pages/Success';
import Study from './pages/Project/Study';
import PPO from './pages/Project/PPO';
import ResultNew from './pages/Project/Result/New';
import PPOEdit from './pages/PPO/Edit';
import ResultEdit from './pages/Project/Result/Edit';
import MyProfile from './pages/MyProfile';
import ResetPassword from './pages/ResetPassword';
import ForcePasswordChange from './pages/ForcePasswordChange';
import ProjectInterestEmail from './pages/ProjectInterestEmail';
import MatchingEmailPreferences from './pages/MatchingEmailPreferences';
import CreateStandAloneResult from './pages/StandAloneResult/Create';
import { logPageView } from './utils/analytics';
import { DetailStandAloneResult } from './pages/StandAloneResult/Detail';
import EditStandAloneResult from './pages/StandAloneResult/Edit';

const Router: React.FC = () => {
  useEffect(() => {
    logPageView();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<Login />} />
        <Route path="/login/two-factor" element={<TwoFactorAuth />} />
        <Route path="/login/email-verification" element={<EmailVerification />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/change-password" element={<ForcePasswordChange />} />

        <Route path="/project-interest-delete" element={<ProjectInterestEmail />} />
        <Route path="/project-email-preferences" element={<MatchingEmailPreferences />} />

        <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        <Route path="/researcher/create" element={<ResearcherCreate />} />
        <Route path="/volunteer/create" element={<VolunteerCreate />} />

        <Route path="/study/new" element={<ProtectedRoute><StudyNew /></ProtectedRoute>} />
        <Route path="/study/edit/:id/" element={<ProtectedRoute><StudyEdit /></ProtectedRoute>} />

        <Route path="/project/study">
          <Route path=":id/results-form" element={<ProtectedRoute><ResultNew /></ProtectedRoute>} />
          <Route path=":id/result/edit" element={<ProtectedRoute><ResultEdit /></ProtectedRoute>} />
          <Route path=":id" element={<ProtectedRoute><Study /></ProtectedRoute>} />
        </Route>

        <Route path="/ppo/new" element={<ProtectedRoute><PPONew /></ProtectedRoute>} />
        <Route path="/ppo/edit/:id/" element={<ProtectedRoute><PPOEdit /></ProtectedRoute>} />
        <Route path="/project/ppo">
          <Route path=":id/results-form" element={<ProtectedRoute><ResultNew /></ProtectedRoute>} />
          <Route path=":id/result/edit" element={<ProtectedRoute><ResultEdit /></ProtectedRoute>} />
          <Route path=":id" element={<ProtectedRoute><PPO /></ProtectedRoute>} />
        </Route>

        <Route path="/result/standalone/results-form" element={<ProtectedRoute><CreateStandAloneResult /></ProtectedRoute>} />
        <Route path="/result/standalone/:id" element={<ProtectedRoute><DetailStandAloneResult /></ProtectedRoute>} />
        <Route path="/result/standalone/edit/:id" element={<ProtectedRoute><EditStandAloneResult /></ProtectedRoute>} />

        <Route path="/myProfile">
          <Route path="" element={<ProtectedRoute><MyProfile /></ProtectedRoute>} />
          <Route path="researcher-preferences" element={<ProtectedRoute><MyProfile /></ProtectedRoute>} />
          <Route path="email-notifications" element={<ProtectedRoute><MyProfile /></ProtectedRoute>} />
          <Route path="password-reset" element={<ProtectedRoute><MyProfile /></ProtectedRoute>} />
        </Route>

        <Route path="/success/:id" element={<ProtectedRoute><Success /></ProtectedRoute>} />
        <Route path="*" element={<P404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
