export const EMPTY_STATE_EXISTING_STUDY = {
  MESSAGE: "You currently don't have any projects.",
  LINKS: {
    PPO: {
      LABEL: 'Patient Partner Research Opportunity',
      URL: '/ppo/new',

    },
    STUDY: {
      LABEL: 'Add Research Study',
      URL: '/study/new',
    },
  },
};
