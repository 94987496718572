import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { enrollmentStatusOptions, projectStatus } from '../../utils/researcherConst';
import Button from '../Button';
import { LinkComponent } from '../Link';
import { SelectInput } from '../SelectInput';
import StatusTag from '../StatusTag';
import { Footer } from './components/footer';
import {
  TableStyled, HeaderRowStyled, HeaderCellStyled, CellStyled, RowStyled, TableScroller,
} from './styles';
import { formateDate } from '../../utils/formateDate';
import { EmptyState } from '../EmptyState';
import { Checkbox } from '../CheckBox';

type TableProps = {
  columnHeaders: { header: string, key: string }[]
  tableData: any[]
  tableType?: string
  projectId?: string
  handleFollowUpUpdate?: (enrollment_status: string, volunteer_id: string, project_id: string) => void
  emptyStateMessage?: {
    title: string,
    description: string
    path?: string
    url?: string
    list?: string[]
  }
  isLoading?: boolean
  checkboxHandler?: (value: number, checked: boolean) => void
  selectedCheckboxs?: number[]
};

type PageNumber = {
  label: string,
  value: string
}

export const Table = ({
  columnHeaders, tableData, tableType, projectId, handleFollowUpUpdate, emptyStateMessage,
  isLoading = false, checkboxHandler, selectedCheckboxs,
}: TableProps) => {
  const [pageNumber, setPageNumber] = useState<PageNumber>({ value: '1', label: '1' });
  const [filterTableData, setFilterTableData] = useState(tableData);
  const [currentRows, setCurrentRows] = useState(tableData);
  const [rowCount, setRowCount] = useState(10);
  const [sortBy, setSortBy] = useState('nick_name');
  const [sortDir, setSortDir] = useState('asc');
  const [totalPages, setTotalPages] = useState(Math.ceil(tableData.length / rowCount));

  const rowOption = [5, 10, 20, 25, 50, 100];

  const handleRowCount = (rowObject: { value: string, label: string }) => {
    setRowCount(Number(rowObject.value));
  };

  const sortTableData = (list: any[]) => list.sort((a: any, b: any) => {
    if (typeof a[sortBy] === 'string') {
      if (sortDir === 'asc') {
        if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
          return -1;
        }
        if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
          return 1;
        }
        return 0;
      }
      if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
        return -1;
      }
      if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
        return 1;
      }
      return 0;
    }

    if (sortDir === 'asc') {
      if (a[sortBy] < b[sortBy]) {
        return -1;
      }
      if (a[sortBy] < b[sortBy]) {
        return 1;
      }
      return 0;
    }

    if (a[sortBy] > b[sortBy]) {
      return -1;
    }
    if (a[sortBy] > b[sortBy]) {
      return 1;
    }
    return 0;
  });

  const filterTable = () => {
    let newList = tableData.filter((data) => {
      if (typeof data[sortBy] === 'number') {
        return String(data[sortBy]);
      }
      if (typeof data[sortBy] !== 'string' && sortBy === 'volunteer_id') {
        return String(data[sortBy]);
      }
      if (data[sortBy]) {
        return data[sortBy].toLowerCase();
      }
      return false;
    });
    if (sortBy) {
      newList = sortTableData(newList);
    }
    setFilterTableData(newList);
  };

  useEffect(() => {
    filterTable();
  }, [sortBy, sortDir]);

  useEffect(() => {
    setFilterTableData(tableData);
  }, [tableData]);

  useEffect(() => {
    setTotalPages(Math.ceil(tableData.length / rowCount));
  }, [rowCount, tableData]);

  useEffect(() => {
    const allrows = [...filterTableData];
    const currentRowArray = allrows.slice((Number(pageNumber?.value) - 1) * rowCount, (Number(pageNumber?.value) - 1) * rowCount + rowCount);
    setCurrentRows(currentRowArray);
  }, [pageNumber, rowCount, filterTableData]);

  return (
    <>
      <TableScroller>
        <TableStyled>
          <thead>
            <HeaderRowStyled>
              {columnHeaders.map((header: { header: string; key: string; }, index: number) => (
                <HeaderCellStyled
                  key={header.key}
                  data-column={index}
                  type={header.key}
                  tableType={tableType}
                >
                  <div>
                    {header.key === 'check' && (
                      <Checkbox
                        label=""
                        isChecked={filterTableData?.length === selectedCheckboxs?.length}
                        onChange={(checked) => { if (checkboxHandler) checkboxHandler(0, checked); }}
                      />
                    )}
                    {header.header !== '' && header.key !== 'check' && (
                      <Button
                        id={`headerBtn-${header.key}`}
                        theme="tableHeader"
                        onClick={() => {
                          setSortBy(header.key);
                          setSortDir(sortDir === 'asc' ? 'desc' : 'asc');
                        }}
                        buttonWidth="100%"
                      >
                        {header.header}
                        {sortBy === header.key && sortDir === 'asc' && (
                          <FontAwesomeIcon icon="caret-up" />
                        )}
                        {sortBy === header.key && sortDir === 'desc' && (
                          <FontAwesomeIcon icon="caret-down" />
                        )}
                      </Button>
                    )}
                  </div>
                </HeaderCellStyled>
              ))}
            </HeaderRowStyled>
          </thead>
          <tbody>

            {currentRows.map((row: any, index) => (
              <RowStyled
                key={`${row?.volunteer_id}-${index}`}
                hoverAction
                hightLighted={!!(row?.enrollment_status && row.enrollment_status === 'not_contacted')}
                selected={selectedCheckboxs?.includes(row?.volunteer_id) || false}
              >
                {columnHeaders.map((header: any) => (
                  <CellStyled
                    key={`${row?.volunteer_id}-${header.key}`}
                    type={header.key}
                    tableType={tableType}
                  >
                    {tableType === 'volunteer_table' && (
                      <>
                        {header.key === 'check' && (
                          <div>
                            <Checkbox
                              label=""
                              isChecked={selectedCheckboxs?.includes(row?.volunteer_id) || false}
                              onChange={
                                (checked) => {
                                  if (checkboxHandler) checkboxHandler(row.volunteer_id, checked);
                                }
                              }
                            />
                          </div>
                        )}
                        {header.key === 'volunteer_id' && (<div>{row[header.key]}</div>)}
                        {header.key === 'first_name' && (<div>{row[header.key]}</div>)}
                        {header.key === 'last_name' && (<div>{row[header.key]}</div>)}
                        {header.key === 'birth_date' && (<div>{row[header.key].substring(0, 10)}</div>)}
                        {header.key === 'email' && (<div>{row[header.key]}</div>)}
                        {header.key === 'phone_number' && (<div> {row[header.key].substring(row[header.key].length - 10, row[header.key].length)}</div>)}
                        {header.key === 'gender' && (<div>{row[header.key]?.split(',').join(', ')}</div>)}
                        {header.key === 'enrollment_status' && (
                          <SelectInput
                            label=""
                            value={row[header.key]}
                            onChange={(value) => { if (handleFollowUpUpdate && projectId) handleFollowUpUpdate(value, row.volunteer_id, projectId); }}
                            id=""
                            options={enrollmentStatusOptions}
                            isRequired={false}
                            theme="secondary"
                          />
                        )}
                      </>
                    )}

                    {tableType === 'site_volunteer_table' && (
                      <>
                        {header.key === 'date_joined' && (<div>{formateDate(new Date(row[header.key]))}</div>)}
                        {header.key === 'first_name' && (<div>{row[header.key]}</div>)}
                        {header.key === 'last_name' && (<div>{row[header.key]}</div>)}
                        {header.key === 'id' && (<div>{row[header.key]}</div>)}
                        {header.key === 'birth_date' && (<div>{formateDate(new Date(row[header.key]))}</div>)}
                        {header.key === 'email' && (<div>{row[header.key]}</div>)}
                        {header.key === 'phone_number' && (<div> {row[header.key].substring(row[header.key].length - 10, row[header.key].length)}</div>)}
                      </>
                    )}

                    {!tableType && (
                      <LinkComponent
                        url={row.type === 'Standalone Result' ? `result/standalone/${row.id}` : `/project/${row?.type}/${row.id}`}
                        linkWidth="100%"
                        theme="tableCell"
                      >
                        {header.key === 'nick_name' && (
                          <div>
                            {row[header.key] ? row[header.key] : '#Click here to view your Draft Project#'}
                          </div>
                        )}
                        {header.key === 'id' && (
                          <div>
                            {row[header.key]}
                          </div>
                        )}
                        {header.key === 'type' && (
                          <div>
                            {row[header.key] === 'ppo' && 'PPO'}
                            {row[header.key] === 'study' && 'Study'}
                            {row[header.key] === 'Standalone Result' && 'Standalone Result'}
                          </div>
                        )}
                        {header.key === 'recruitment_end' && (
                          <div>
                            {row[header.key]?.substring(0, 10)}
                          </div>
                        )}
                        {header.key === 'status' && (
                          <StatusTag
                            label={projectStatus[row[header.key]] || row[header.key]}
                            theme={row[header.key]}
                          />
                        )}
                      </LinkComponent>
                    )}
                  </CellStyled>
                ))}
              </RowStyled>
            ))}
            {filterTableData.length < 1 && !emptyStateMessage && (
              <>
                <RowStyled>
                  {columnHeaders.map(() => (
                    <CellStyled key={Math.random()} />
                  ))}
                </RowStyled>
                <RowStyled>
                  {columnHeaders.map(() => (
                    <CellStyled key={Math.random()} />
                  ))}
                </RowStyled>
                <RowStyled>
                  {columnHeaders.map(() => (
                    <CellStyled key={Math.random()} />
                  ))}
                </RowStyled>
              </>
            )}
          </tbody>
        </TableStyled>
      </TableScroller>
      {filterTableData.length === 0 && emptyStateMessage && !isLoading && (
        <EmptyState message={emptyStateMessage} />
      )}
      <Footer
        setPageNumber={setPageNumber}
        totalPages={totalPages}
        rowCount={rowCount}
        setRowCount={handleRowCount}
        rowOption={rowOption}
        pageNumber={{ label: pageNumber.label, value: pageNumber.value }}
      />
    </>
  );
};
