import React from 'react';
import { ResultMainFormPropTypes } from './types';
import {
  TeamMemberSection,
  StudyAffiliationSection,
  StudyDetailSection,
  StudyLocationSection,
  StudyContactSection,
  StudyCompletedSection,
  AdditionalInformationSection,
  CommentSection,
  AdminCommentSection,
} from './FormSections';

export const ResultMainForm = ({
  addInputHandler,
  teamMembers,
  collaboratingOrganizations,
  resultInfo,
  selectedHealthCategories,
  categoriesMap,
  forceChange,
  healthAuthorityRegions,
  selectedHealthAuthorityRegions,
  selectedRegionalCenters,
  currentFile,
  hospitals,
  errors,
  updateResultInfoHandler,
  changeAddedInputHandler,
  removeInputHandler,
  setHealthCategoriesHandler,
  checkboxInputHandler,
  fileHandler,
  removeFileHandler,
  checkForReseacherAccount,
}: ResultMainFormPropTypes) => (
  <div>
    <TeamMemberSection
      resultInfo={resultInfo}
      addInputHandler={addInputHandler}
      changeAddedInputHandler={changeAddedInputHandler}
      removeInputHandler={removeInputHandler}
      teamMembers={teamMembers}
      updateResultInfoHandler={updateResultInfoHandler}
      checkForReseacherAccount={checkForReseacherAccount}
      checkboxInputHandler={checkboxInputHandler}
      errors={errors}
    />

    <StudyAffiliationSection
      resultInfo={resultInfo}
      addInputHandler={addInputHandler}
      changeAddedInputHandler={changeAddedInputHandler}
      removeInputHandler={removeInputHandler}
      collaboratingOrganizations={collaboratingOrganizations}
      selectedRegionalCenters={selectedRegionalCenters}
      checkboxInputHandler={checkboxInputHandler}
      errors={errors}
    />

    <StudyDetailSection
      resultInfo={resultInfo}
      updateResultInfoHandler={updateResultInfoHandler}
      categoriesMap={categoriesMap}
      checkboxInputHandler={checkboxInputHandler}
      setHealthCategoriesHandler={setHealthCategoriesHandler}
      selectedHealthCategories={selectedHealthCategories}
      forceChange={forceChange}
      errors={errors}
    />

    <StudyLocationSection
      healthAuthorityRegions={healthAuthorityRegions}
      checkboxInputHandler={checkboxInputHandler}
      selectedHealthAuthorityRegions={selectedHealthAuthorityRegions}
      errors={errors}
    />

    <StudyContactSection
      resultInfo={resultInfo}
      updateResultInfoHandler={updateResultInfoHandler}
      errors={errors}
    />

    <StudyCompletedSection
      resultInfo={resultInfo}
      hospitals={hospitals}
      updateResultInfoHandler={updateResultInfoHandler}
      checkboxInputHandler={checkboxInputHandler}
      errors={errors}
    />

    <AdditionalInformationSection
      resultInfo={resultInfo}
      updateResultInfoHandler={updateResultInfoHandler}
      fileHandler={fileHandler}
      removeFileHandler={removeFileHandler}
      currentFile={currentFile}
      errors={errors}
    />

    <CommentSection
      resultInfo={resultInfo}
      updateResultInfoHandler={updateResultInfoHandler}
      errors={errors}
    />

    <AdminCommentSection
      resultInfo={resultInfo}
    />
  </div>
);
