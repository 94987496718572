import React from 'react';
import { ExistingProjectEmptyIcon, LinkComponent } from '../../../components';
import * as Styled from './styles';
import { EMPTY_STATE_EXISTING_STUDY } from './const';

export const EmptyStateExistingStudy = () => (
  <Styled.EmptyStateStyled>
    <Styled.IconWrapper>
      <ExistingProjectEmptyIcon />
    </Styled.IconWrapper>
    <Styled.BodyWrapper>
      <p>{EMPTY_STATE_EXISTING_STUDY.MESSAGE}</p>

      <Styled.LinkDivStyled>
        <LinkComponent
          url={EMPTY_STATE_EXISTING_STUDY.LINKS.STUDY.URL}
          theme="website"
        >
          <Styled.LinkTextStyled>
            {EMPTY_STATE_EXISTING_STUDY.LINKS.STUDY.LABEL}
          </Styled.LinkTextStyled>
        </LinkComponent>
        <p>or</p>
        <LinkComponent
          url={EMPTY_STATE_EXISTING_STUDY.LINKS.PPO.URL}
          theme="website"
        >
          <Styled.LinkTextStyled>
            {EMPTY_STATE_EXISTING_STUDY.LINKS.PPO.LABEL}
          </Styled.LinkTextStyled>
        </LinkComponent>
      </Styled.LinkDivStyled>
    </Styled.BodyWrapper>
  </Styled.EmptyStateStyled>
);
