import styled from 'styled-components';
import toRem from '../../../../utils/toRem';
import { colours, fonts } from '../../../../styles/theme';

export const SideMenuWrapper = styled.div`
  padding: ${toRem(24)} ${toRem(14)};
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${toRem(56)};
`;

export const StatusWrapper = styled.div`
  width: fit-content;
`;

export const ErrorWrapper = styled.div`
  border: 1px solid ${colours.colour7};
  padding: 0.875rem;
  margin: 4rem 0.875rem 0.875rem;
  border-radius: 4px;
  ${fonts.font1.H14}
`;

export const ErrorStyled = styled.div`
  margin-bottom: 0.875rem;
  color: ${colours.colour7};
  a {
    text-decoration: underline;
    line-height: 1.33rem;
  }
`;

export const ErrorTitleStyled = styled.p`
  text-align: center;
`;
