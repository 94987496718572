import styled from 'styled-components';
import { fonts, colours } from '../../../../../styles/theme';
import toRem from '../../../../../utils/toRem';

export const MainBodyWrapper = styled.div`
  flex-grow: 1;
`;

export const SectionStyled = styled.section`
  margin: ${toRem(50)} 0;
  padding: 0 0 ${toRem(50)};
  ${fonts.font1.H16};
  line-height: ${toRem(26)};
  border-bottom: 1px solid ${colours.colour29};
  color: ${colours.colour6};
  word-break: break-word;
  overflow-wrap: break-word;
`;

export const TitleStyled = styled.h3`
  ${fonts.font1.H24B};
  margin-bottom: ${toRem(30)};
`;

export const HealthCategoryWrapper = styled.div`
display: grid;
grid-template-columns: repeat(3, minmax(0, 1fr));
`;

export const LabelSpanStyled = styled.span`
  ${fonts.font1.H16B};
`;

export const DescriptionParapWrapper = styled.div`
  margin-bottom: ${toRem(12)};
`;

export const LinkTextWrapper = styled.p`
  ${fonts.font1.H16};
`;

export const ResourceSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(8)};
`;

export const EditLinkStyled = styled.div`
  width: fit-content;
  display: flex;
  gap: ${toRem(16)};
`;

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: end;
`;
