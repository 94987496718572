import environment from '../config/environment';
import { useCookies } from './useCookies';

export const useResearcherAPI = () => {
  const { getCookie, deleteCookie } = useCookies();

  const getResearcherByEmail = async (email: string) => {
    const response = await fetch(`${environment.api.baseUrl}/user/email/${email}/researcher`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Auth: getCookie(environment.app.cookieName),
      },
    });
    const principalInvestigater = await response.json();
    if (principalInvestigater.expiredAuth) { deleteCookie(environment.app.cookieName); }
    return principalInvestigater;
  };

  const getResearcherByID = async (id: string) => {
    const response = await fetch(`${environment.api.baseUrl}/user/${id}/researcher`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Auth: getCookie(environment.app.cookieName),
      },
    });
    const researcher = await response.json();
    if (researcher.expiredAuth) { deleteCookie(environment.app.cookieName); }
    return researcher;
  };

  const createResult = async (
    results: FormData,
  ) => {
    const response = await fetch(`${environment.api.baseUrl}/results`, {
      method: 'POST',
      headers: {
        Auth: getCookie(environment.app.cookieName),
      },
      body: results,
    });
    const result = await response.json();
    if (result.error) throw new Error(result.error);
    if (result.expiredAuth) { deleteCookie(environment.app.cookieName); }
    return result;
  };

  const updateResult = async (
    results: FormData,
  ) => {
    const response = await fetch(`${environment.api.baseUrl}/results`, {
      method: 'PATCH',
      headers: {
        Auth: getCookie(environment.app.cookieName),
      },
      body: results,
    });
    const result = await response.json();
    if (result.error) throw new Error(result.error);
    if (result.expiredAuth) { deleteCookie(environment.app.cookieName); }
    return result;
  };

  const getResult = async (projectId: number, type: string) => {
    const response = await fetch(`${environment.api.baseUrl}/results/${projectId}/${type}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Auth: getCookie(environment.app.cookieName),
      },
    });
    const project = await response.json();
    return project;
  };

  const getResearchersStudy = async () => {
    const response = await fetch(`${environment.api.baseUrl}/projects?ppoResult=true`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Auth: getCookie(environment.app.cookieName),
      },
    });
    const result = await response.json();
    return result.map((res: any) => ({ value: res.id, label: res.nick_name }));
  };

  const createStandAloneResults = async (results: FormData) => {
    const response = await fetch(`${environment.api.baseUrl}/resultStandAlone`, {
      method: 'POST',
      headers: {
        Auth: getCookie(environment.app.cookieName),
      },
      body: results,
    });
    const result = await response.json();
    if (result.error) throw new Error(result.error);
    if (result.expiredAuth) { deleteCookie(environment.app.cookieName); }
    return result;
  };

  const updateStandAloneResults = async (results: FormData) => {
    const response = await fetch(`${environment.api.baseUrl}/resultStandAlone`, {
      method: 'PATCH',
      headers: {
        Auth: getCookie(environment.app.cookieName),
      },
      body: results,
    });
    const result = await response.json();
    if (result.error) throw new Error(result.error);
    if (result.expiredAuth) { deleteCookie(environment.app.cookieName); }
    return result;
  };

  return {
    getResearcherByEmail,
    getResearcherByID,
    createResult,
    getResearchersStudy,
    getResult,
    updateResult,
    createStandAloneResults,
    updateStandAloneResults,
  };
};
