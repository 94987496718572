/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import * as Styled from './styles';
import { MainDetailBodyPropTypes } from './types';
import { STAND_ALONE_RESULT_CONST } from '../../const';
import { paraphFormater } from '../../../../../utils';
import TextBlockWithLink from '../../../../../components/TextBlockWithLinks';
import { LinkComponent } from '../../../../../components';

export const MainDetailBody = ({
  result,
  sectionRefs,
}: MainDetailBodyPropTypes) => (
  <Styled.MainBodyWrapper>

    {result.hasAccess && (
      <Styled.LinkWrapper>
        <LinkComponent
          url={`${STAND_ALONE_RESULT_CONST.LINKS.EDIT.URL}${result.id}`}
          theme="buttonSecondary"
        >
          <Styled.EditLinkStyled>
            <FontAwesomeIcon icon={faPenToSquare} />
            {STAND_ALONE_RESULT_CONST.LINKS.EDIT.LABEL}
          </Styled.EditLinkStyled>
        </LinkComponent>
      </Styled.LinkWrapper>
    )}

    <Styled.SectionStyled
      id={STAND_ALONE_RESULT_CONST.TAB_ID.PURPOSE}
      ref={(ref) => (sectionRefs.current[STAND_ALONE_RESULT_CONST.TAB_ID.PURPOSE] = ref as HTMLDivElement)}
    >
      <Styled.TitleStyled>
        {STAND_ALONE_RESULT_CONST.SESSION_HEADERS.PURPOSE}
      </Styled.TitleStyled>
      <TextBlockWithLink
        paragraphArray={paraphFormater(result.purpose)}
      />
    </Styled.SectionStyled>

    <Styled.SectionStyled
      id={STAND_ALONE_RESULT_CONST.TAB_ID.RESULTS}
      ref={(ref) => (sectionRefs.current[STAND_ALONE_RESULT_CONST.TAB_ID.RESULTS] = ref as HTMLDivElement)}
    >
      <Styled.TitleStyled>
        {STAND_ALONE_RESULT_CONST.SESSION_HEADERS.RESULTS}
      </Styled.TitleStyled>

      <TextBlockWithLink
        paragraphArray={paraphFormater(result.result_summary)}
      />
    </Styled.SectionStyled>

    {(result.is_por || result.is_ppo) && (
      <Styled.SectionStyled
        id={STAND_ALONE_RESULT_CONST.TAB_ID.ENGAGEMENT}
        ref={(ref) => (sectionRefs.current[STAND_ALONE_RESULT_CONST.TAB_ID.ENGAGEMENT] = ref as HTMLDivElement)}
      >
        <Styled.TitleStyled>
          {STAND_ALONE_RESULT_CONST.SESSION_HEADERS.ENGAGEMENT}
        </Styled.TitleStyled>
        <Styled.DescriptionParapWrapper>
          <p>
            <Styled.LabelSpanStyled>
              {STAND_ALONE_RESULT_CONST.LABEL.ENGAGEMENT_NUMBER}
            </Styled.LabelSpanStyled>
            {result.patient_partners_engagement_number}
          </p>
        </Styled.DescriptionParapWrapper>

        {result.patient_partners_engagement_method && (
          <TextBlockWithLink
            paragraphArray={paraphFormater(result.patient_partners_engagement_method)}
          />
        )}
      </Styled.SectionStyled>
    )}

    {result.study_limitations && (
      <Styled.SectionStyled
        id={STAND_ALONE_RESULT_CONST.TAB_ID.LIMITATIONS}
        ref={(ref) => (sectionRefs.current[STAND_ALONE_RESULT_CONST.TAB_ID.LIMITATIONS] = ref as HTMLDivElement)}
      >
        <Styled.TitleStyled>
          {STAND_ALONE_RESULT_CONST.SESSION_HEADERS.LIMITATIONS}
        </Styled.TitleStyled>
        <TextBlockWithLink
          paragraphArray={paraphFormater(result.study_limitations)}
        />
      </Styled.SectionStyled>
    )}

    {(result.resource_file_name || result.resource_website_name || result.resource_website_url) && (
      <Styled.SectionStyled
        id={STAND_ALONE_RESULT_CONST.TAB_ID.RESOURCES}
        ref={(ref) => (sectionRefs.current[STAND_ALONE_RESULT_CONST.TAB_ID.RESOURCES] = ref as HTMLDivElement)}
      >
        <Styled.TitleStyled>
          {STAND_ALONE_RESULT_CONST.SESSION_HEADERS.RESOURCES}
        </Styled.TitleStyled>
        <Styled.ResourceSectionStyled>

          {result.resource_website_url && (
            <LinkComponent
              url={result.resource_website_url}
              newWindow
            >
              <Styled.LinkTextWrapper>
                {result.resource_website_name || result.resource_website_url}
              </Styled.LinkTextWrapper>
            </LinkComponent>
          )}

          {result.resourceLink && (
            <LinkComponent
              url={result.resourceLink}
              newWindow
            >
              <Styled.LinkTextWrapper>
                {result.resource_file_name || result.resource_file_path}
              </Styled.LinkTextWrapper>
            </LinkComponent>
          )}
        </Styled.ResourceSectionStyled>
      </Styled.SectionStyled>
    )}

    <Styled.SectionStyled
      id={STAND_ALONE_RESULT_CONST.TAB_ID.HEALTH_CATEGORY}
    >
      <Styled.TitleStyled>
        {STAND_ALONE_RESULT_CONST.SESSION_HEADERS.HEALTH_CATEGORY}
      </Styled.TitleStyled>
      <Styled.HealthCategoryWrapper>
        {result.health_categories.map((category) => (
          <p key={category}>{category}</p>
        ))}
      </Styled.HealthCategoryWrapper>
    </Styled.SectionStyled>
  </Styled.MainBodyWrapper>
);
