import React from 'react';
import FormSection from '../../../../../components/FormSelection';
import { RESULTS_MAIN_FORM } from '../const';
import FormSubtitle from '../../../../../components/FormSubtitle';
import { TextInput } from '../../../../../components';
import { StudyContactSectionPropTypes } from '../types';
import { researcherPosition } from '../../../../../utils';
import { DropdownSelect } from '../../../../../components/DropdownSelect';
import * as Styled from '../styles';

export const StudyContactSection = ({
  resultInfo,
  updateResultInfoHandler,
  errors,
}: StudyContactSectionPropTypes) => (
  <FormSection
    title={RESULTS_MAIN_FORM.STUDY_CONTACT.TITLE}
    subText={RESULTS_MAIN_FORM.STUDY_CONTACT.DESCRIPTION}
  >
    <Styled.FormChildrenWrapper>
      <FormSubtitle
        subtitle={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.CONTACT_ONE_LABEL}
        margin="xsmall"
        isRequired
      />
      <TextInput
        id={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.NAME.NAME}
        label={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.NAME.LABEL}
        description={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.NAME.DESCRIPTION}
        setTextValue={(value) => updateResultInfoHandler(RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.NAME.NAME, value)}
        textValue={resultInfo.contact1_name || ''}
        maxLength={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.NAME.LIMIT}
        theme="form"
        error={errors?.contact1_name}
      />

      <DropdownSelect
        id={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.POSITION.NAME}
        label={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.POSITION.LABEL}
        selected={{
          value: resultInfo.contact1_position,
          label: resultInfo.contact1_position,
        }}
        onChange={(option: { label: string }) => updateResultInfoHandler(RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.POSITION.NAME, option.label)}
        options={researcherPosition}
        placeholder={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.POSITION.PLACEHOLDER}
        theme="form"
        required
        error={errors?.contact1_position}
      />
      <Styled.InputGridWrapper>
        <TextInput
          id={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.EMAIL.NAME}
          label={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.EMAIL.LABEL}
          textValue={resultInfo.contact1_email}
          setTextValue={(value) => updateResultInfoHandler(RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.EMAIL.NAME, value)}
          theme="form"
          placeholder={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.EMAIL.PLACEHOLDER}
          error={errors?.contact1_email}
        />
        <TextInput
          id={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.PHONE.NAME}
          label={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.PHONE.LABEL}
          textValue={resultInfo.contact1_phone}
          setTextValue={(value) => updateResultInfoHandler(RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.PHONE.NAME, value)}
          placeholder={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.PHONE.PLACEHOLDER}
          theme="form"
          isRequired={false}
          error={errors?.contact1_phone}
        />

        <TextInput
          id={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.EXTENSION.NAME}
          label={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.EXTENSION.LABEL}
          textValue={resultInfo.contact1_phone_extension}
          setTextValue={(value) => updateResultInfoHandler(RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.EXTENSION.NAME, value)}
          isRequired={false}
          theme="form"
          error={errors?.contact1_phone_extension}
        />
      </Styled.InputGridWrapper>

      <FormSubtitle
        subtitle={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.CONTACT_TWO_LABEL}
        margin="xsmall"
      />
      <TextInput
        id={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.NAME.NAME_2}
        label={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.NAME.LABEL}
        description={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.NAME.DESCRIPTION}
        setTextValue={(value) => updateResultInfoHandler(RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.NAME.NAME_2, value)}
        textValue={resultInfo.contact2_name || ''}
        maxLength={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.NAME.LIMIT}
        theme="form"
        isRequired={false}
      />

      <DropdownSelect
        id={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.POSITION.NAME_2}
        label={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.POSITION.LABEL}
        selected={{
          value: resultInfo.contact2_position,
          label: resultInfo.contact2_position,
        }}
        onChange={(value: string) => updateResultInfoHandler(RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.POSITION.NAME_2, value)}
        options={researcherPosition}
        placeholder={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.POSITION.PLACEHOLDER}
        theme="form"
      />
      <Styled.InputGridWrapper>
        <TextInput
          id={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.EMAIL.NAME_2}
          label={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.EMAIL.LABEL}
          textValue={resultInfo.contact2_email}
          setTextValue={(value) => updateResultInfoHandler(RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.EMAIL.NAME_2, value)}
          theme="form"
          placeholder={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.EMAIL.PLACEHOLDER}
          isRequired={false}
        />
        <TextInput
          id={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.PHONE.NAME_2}
          label={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.PHONE.LABEL}
          textValue={resultInfo.contact2_phone}
          setTextValue={(value) => updateResultInfoHandler(RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.PHONE.NAME_2, value)}
          placeholder={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.PHONE.PLACEHOLDER}
          theme="form"
          isRequired={false}
        />

        <TextInput
          id={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.EXTENSION.NAME_2}
          label={RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.EXTENSION.LABEL}
          textValue={resultInfo.contact2_phone_extension}
          setTextValue={(value) => updateResultInfoHandler(RESULTS_MAIN_FORM.STUDY_CONTACT.INPUTS.EXTENSION.NAME_2, value)}
          isRequired={false}
          error={errors?.contact2_phone_extension}
          theme="form"
        />
      </Styled.InputGridWrapper>
    </Styled.FormChildrenWrapper>
  </FormSection>
);
