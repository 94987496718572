import React from 'react';

export const ExistingProjectEmptyIcon = () => (
  <svg width="140" height="111" viewBox="0 0 252 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Layer_1" clipPath="url(#clip0_5519_9947)">
      <path id="Vector" opacity="0.05" d="M250.891 85.5856C249.084 109.018 235.977 132.414 218.593 148.973C197.461 169.101 169.339 180.046 141.04 185.429C-14.9073 215.092 -37.6436 79.7113 57.591 24.5374C94.201 3.32604 138.721 -4.45767 179.965 6.16666C200.605 11.4829 221.013 22.0423 235.249 38.1776C247.788 52.3888 252.171 68.9961 250.891 85.5856Z" fill="#EAEAEB" />
      <g id="Group">
        <path id="Vector_2" d="M162.859 60.5377L181.585 166.75C182.728 173.225 178.404 179.399 171.928 180.541L62.8619 199.77C58.8013 200.486 54.8348 199.488 51.7022 197.296C48.5728 195.105 46.2773 191.717 45.5602 187.655L27.3614 84.4258C26.2193 77.9513 30.5427 71.7769 37.0189 70.6348L149.068 50.8802C155.543 49.7381 161.717 54.0615 162.859 60.5377Z" fill="#BEC0C5" />
        <path id="Vector_3" d="M105.424 35.8247L87.5062 103.212C85.8168 109.566 89.598 116.086 95.9518 117.776L191.62 143.213C197.973 144.903 204.494 141.121 206.183 134.768L224.101 67.38C225.79 61.0261 222.009 54.5058 215.655 52.8164L119.988 27.3791C113.634 25.6896 107.114 29.4708 105.424 35.8247Z" fill="white" />
        <path id="Vector_4" d="M67.6483 55.9116L57.5343 124.903C56.5807 131.408 61.081 137.455 67.586 138.408L165.531 152.767C172.036 153.72 178.082 149.22 179.036 142.715L189.15 73.7234C190.104 67.2184 185.603 61.1719 179.098 60.2183L81.1534 45.8599C74.6484 44.9062 68.6019 49.4066 67.6483 55.9116Z" fill="#F2F2F3" />
        <path id="Vector_5" d="M53.1099 90.1607L51.09 159.86C50.8995 166.432 56.0726 171.914 62.6444 172.105L161.595 174.972C168.166 175.163 173.648 169.99 173.839 163.418L175.859 93.718C176.049 87.1462 170.876 81.6643 164.304 81.4739L65.3541 78.6062C58.7823 78.4158 53.3004 83.5889 53.1099 90.1607Z" fill="white" />
        <path id="Vector_6" opacity="0.2" d="M64.3364 78.5798L164.311 81.4805L64.8734 74.9248L64.3364 78.5798Z" fill="black" />
        <path id="Vector_7" opacity="0.11" d="M101.93 48.9117L179.11 60.232L102.861 45.4092L101.93 48.9117Z" fill="black" />
        <g id="Group_2">
          <path id="Vector_8" d="M185.148 83.4886C191.622 82.3465 197.798 86.6715 198.939 93.146L199.533 96.5155L210.958 161.58C212.096 168.053 207.772 174.223 201.301 175.363L127.565 188.363L77.5726 197.175L77.5499 197.18L75.1457 183.491L66.1242 132.117C64.987 125.644 69.3103 119.474 75.7833 118.334L134.542 107.974C138.399 107.294 141.674 104.762 143.303 101.201L145.842 95.6492C147.47 92.0883 150.746 89.5559 154.602 88.8746L185.15 83.4886H185.148Z" fill="#D9DADD" />
          <path id="Vector_9" d="M75.123 183.288L77.5711 197.178L62.8845 199.767C70.7315 198.383 76.0623 191.107 75.123 183.288Z" fill="#D9DADD" />
        </g>
      </g>
      <g id="Group_3">
        <path id="Vector_10" d="M155.793 199.999C177.574 199.999 195.23 182.343 195.23 160.562C195.23 138.781 177.574 121.124 155.793 121.124C134.012 121.124 116.355 138.781 116.355 160.562C116.355 182.343 134.012 199.999 155.793 199.999Z" fill="#838791" />
        <g id="Group_4">
          <path id="Vector_11" d="M155.792 142.864V178.258" stroke="white" strokeWidth="9.08479" strokeLinecap="round" strokeLinejoin="round" />
          <path id="Vector_12" d="M138.095 160.562H173.49" stroke="white" strokeWidth="9.08479" strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </g>
      <g id="Group_5">
        <path id="Vector_13" d="M170.067 28.5132L164.13 17.8516" stroke="#BEC0C5" strokeWidth="5.67799" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_14" d="M195.743 36.2839L206.403 30.3447" stroke="#BEC0C5" strokeWidth="5.67799" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_15" d="M185.244 23.3608L191.082 2.83887" stroke="#BEC0C5" strokeWidth="5.67799" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_5519_9947">
        <rect width="250.21" height="200" fill="white" transform="translate(0.895264)" />
      </clipPath>
    </defs>
  </svg>
);
