import React from 'react';
import * as Styled from './styles';
import { ResultSideBarPropTypes } from './types';
import { STAND_ALONE_RESULT_CONST } from '../../const';
import SideBarItem from '../../../../../components/ProjectDetails/components/SideBarItem';
import { regionalCentre } from '../../../../../utils';

export const ResultSideBar = ({
  result,
}: ResultSideBarPropTypes) => (
  <Styled.SideBarStyled>

    {(result.collaborating_organizations || result.is_bc_support_unit_affiliation) && (
      <Styled.SideBarSectionStyled>

        <Styled.SideBarTitleStyled>
          {STAND_ALONE_RESULT_CONST.SESSION_HEADERS.INFORMATION}
        </Styled.SideBarTitleStyled>

        {result.collaborating_organizations && (
          <SideBarItem
            subTitle={STAND_ALONE_RESULT_CONST.LABEL.COLLABORATING_ORGANIZATION}
            icon="building"
          >
            <div>
              {result.collaborating_organizations.map((organization) => (
                <p>{organization}</p>
              ))}
            </div>
          </SideBarItem>
        )}

        {result.is_bc_support_unit_affiliation && (
          <SideBarItem
            subTitle={STAND_ALONE_RESULT_CONST.LABEL.BC_AFFILIATION}
            icon="building"
          >
            <div>
              {result.regional_centers?.map((centre) => (
                <p>{regionalCentre[centre]}</p>
              ))}
            </div>
          </SideBarItem>
        )}
      </Styled.SideBarSectionStyled>
    )}

    <Styled.SideBarSectionStyled>
      <Styled.SideBarTitleStyled>
        {STAND_ALONE_RESULT_CONST.SESSION_HEADERS.LOCATION}
      </Styled.SideBarTitleStyled>
      <SideBarItem
        subTitle={STAND_ALONE_RESULT_CONST.LABEL.PARTICIPATION_TYPE}
        icon="map-marker-alt"
      >
        <p>{result.is_online ? 'Online' : 'In Person'}</p>
      </SideBarItem>

      {!result.is_online && result.hospital && (
        <SideBarItem
          subTitle={result.hospital}
        >
          <div>
            <p>{result.building}</p>
            <p>{result.department}</p>
            <Styled.AddressStyled>
              {result.street}, {result.city}{' '}
              <Styled.UpperCaseSpan>{result.province}</Styled.UpperCaseSpan>
            </Styled.AddressStyled>
            <p>{result.postal_code}</p>
          </div>
        </SideBarItem>
      )}
    </Styled.SideBarSectionStyled>

    <Styled.SideBarSectionStyled size="sm">
      <Styled.SideBarTitleStyled>
        {STAND_ALONE_RESULT_CONST.SESSION_HEADERS.PI}
      </Styled.SideBarTitleStyled>

      <SideBarItem
        subTitle={`${result.pi_first_name} ${result.pi_last_name}`}
        icon="user"
      />
      <SideBarItem
        subTitle={result?.pi_health_authority || ''}
        icon="building"
        theme="secondary"
      />
      <SideBarItem
        subTitle={result.pi_academic_affiliation || ''}
        icon="graduation-cap"
        theme="secondary"
      />

      <SideBarItem
        subTitle={result.pi_email}
        icon="envelope"
        theme="secondary"
      />
    </Styled.SideBarSectionStyled>

    <Styled.SideBarSectionStyled theme="secondary" size="sm">
      <Styled.SideBarTitleStyled>
        {STAND_ALONE_RESULT_CONST.SESSION_HEADERS.CONTACTS}
      </Styled.SideBarTitleStyled>
      <SideBarItem
        subTitle={result.contact1_name}
        icon="user"
      />
      <SideBarItem
        subTitle={result.contact1_position}
        icon="building"
        theme="secondary"
      />

      <SideBarItem
        subTitle={result.contact1_email}
        icon="envelope"
        theme="secondary"
      />

      {result.contact1_phone && (
        <SideBarItem
          subTitle={result.contact1_phone}
          icon="phone"
          theme="secondary"
        />
      )}

      {result.contact1_phone_extension && (
        <SideBarItem
          subTitle={result.contact1_phone_extension}
          theme="secondary"
        />
      )}

      {result.contact2_name && (
        <Styled.ContactWrapper>
          <SideBarItem
            subTitle={result.contact2_name}
            icon="user"
          />
          <SideBarItem
            subTitle={result.contact2_position || ''}
            icon="building"
            theme="secondary"
          />

          <SideBarItem
            subTitle={result.contact2_email || ''}
            icon="envelope"
            theme="secondary"
          />

          {result.contact2_phone && (
            <SideBarItem
              subTitle={result.contact2_phone}
              icon="phone"
              theme="secondary"
            />
          )}

          {result.contact2_phone_extension && (
            <SideBarItem
              subTitle={result.contact2_phone_extension}
              theme="secondary"
            />
          )}
        </Styled.ContactWrapper>
      )}
    </Styled.SideBarSectionStyled>
  </Styled.SideBarStyled>
);
