export const RESULTS_MAIN_FORM = {
  TEAM_MEMBERS: {
    TITLE: 'Principal Investigator & Study Team Members',
    INPUTS: {
      PI: {
        LABEL: 'Principal Investigator',
        DESCRIPTION: "Enter Principal Investigator's email. The PI must have a REACH BC Researcher Account and be listed as the PI on the REB certificate.",
        PLACEHOLDER: 'i.e. name@mail.com',
        NAME: 'pi_email',
        ID: 'pi_id',
      },
      PPO: {
        TITLE: 'Is this a patient partner research opportunity?',
        LABEL: 'Yes, this project is a PPO project.',
        NAME: 'is_ppo',
      },
      PP_CO_INVERSTIGATOR: {
        LABEL: 'Patient Partner Co-Investigator Name',
        DESCRIPTION: 'If a patient partner is a co-investigator on this project and they would like to have their information publicly displayed, enter first and last name. If not, you can leave this blank.',
        NAME: 'pp_co_investigator',
      },
      PP_AFFILIATION_ORGANIZATION: {
        LABEL: 'Patient Partner Affiliated Organization',
        DESCRIPTION: "Enter the patient partner's affiliated organization if applicable.",
        NAME: 'pp_affiliation_organization',
      },
      POR: {
        TITLE: 'Is this a patient-oriented research project?',
        DESCRIPTION: 'Patient-oriented research (POR) is research done in partnership with patients, that answers research questions that matter to patients and aims to improve health care.',
        LABEL: 'Yes, this project is a patient-oriented research (POR) project.',
        PLACEHOLDER: 'i.e. name@mail.com',
        NAME: 'is_por',
        LINK: { PATH: '/', LABEL: 'Learn about POR' },
      },

      TEAM_MEMBER: {
        TITLE: 'Additional Team Members - REACH BC Access',
        DESCRIPTION: "Enter other team members' email. Add team members who require the ability to view and edit the posting or manage any matched Volunteers.",
        PLACEHOLDER: 'i.e. name@mail.com',
        NAME: 'teamMembers',
        BUTTON: {
          ADD: 'Add a team member',
          REMOVE: 'Remove',
        },
      },
    },
  },

  AFFILIATIONS: {
    TITLE: 'Study Affiliations',
    TITLE_OPPORTUNTIY: 'Opportunity Affiliations',
    INPUTS: {
      COLLABORATING_ORGANIZATION: {
        TITLE: 'Collaborating Organizations',
        DESCRIPTION: 'Are you collaborating with another organization?',
        DESCRIPTION_SECONDARY: 'Example: A society, community organization, or other research institution that you are not affiliated with?',
        NAME: 'organization',
        BUTTON: {
          ADD: 'Add an organization',
          REMOVE: 'Remove',
        },
      },
      AFFILIATION: {
        TITLE: 'Is this a BC SUPPORT unit affiliation?',
        DESCRIPTION: 'Is the BC SUPPORT Unit helping provide services, i.e. financial support, coordination, or training etc., to support this opportunity?',
        LABEL: 'Yes, the BC SUPPORT Unit is helping provide services to support this opportunity.',
        NAME: 'is_bc_support_unit_affiliation',
        LINK: { PATH: '/', LABEL: 'Learn about BC SUPPORT Unit' },
      },
      BC_SUPPORT_UNIT: {
        LABEL: 'Select Regional Centre(s)',
        NAME: 'regional_centers',
        DEFAULT: 'All',
      },
    },
  },

  DETAILS: {
    TITLE: 'Study Details',
    INPUTS: {
      TITLE: {
        LABEL: 'Study Title',
        DESCRIPTION: 'The full title of the research study (i.e. Protocol title). (Max character limit is 500).',
        NAME: 'title',
        COUNT: 500,
      },
      HEADLINE: {
        LABEL: 'Study Headline',
        DESCRIPTION: 'Give your study a catchy lay-friendly headline that will attract Volunteers. ',
        DESCRIPTION_SECONDARY: "Example: Can we predict Alzheimer's Disease?.  This will also be used as your quick reference to access the study on your dashboard. (Max character limit is 90) ",
        NAME: 'nick_name',
        COUNT: 90,
      },
      PURPOSE: {
        LABEL: 'Study Purpose',
        LIST: [
          'Explain the purpose of this study in 3-4 sentences.',
          'Use lay-friendly language, and make sure any acronyms are spelled out on first use.',
          'Use any key words that would help a Volunteer find your study when searching in the REACH BC Directory.',
        ],

        DESCRIPTION_SECONDARY: ['Example: To examine the effect of a peer support intervention on improving and sustaining glycemic control and diabetes-specific quality of life. (Max character limit is 450).'],
        NAME: 'purpose',
        COUNT: 450,
      },

      HEALTH_CATEGORIES: {
        LABEL: 'Health Research Categories',
        DESCRIPTION: 'Which of the following categories does your research study fall under? Choose at least one subcategory below. If your study does not fit in one of the subcategories listed, select the subcategory “Other” in the most appropriate category for your research.',
      },
    },
  },

  LOCATION: {
    TITLE: 'Study Location',
    DESCRIPTION: 'Where did your study take place?',
    INPUTS: {
      REGIONS: {
        LABEL: 'Applicable Regions',
        DESCRIPTION: 'Select all that apply.',
        LINK: { PATH: '/', LABEL: 'Learn about POR' },
        NAME: 'health_authority_region',
      },
    },
  },

  STUDY_CONTACT: {
    TITLE: 'Study Contact',
    DESCRIPTION: 'Who will be the study contact to receive inquiries from Volunteers? Enter up to two contacts. If contacts require online access to the study, they must also be added under Additional Team Member - REACH BC Access.',

    INPUTS: {
      CONTACT_ONE_LABEL: 'Study Contact 1',
      CONTACT_TWO_LABEL: 'Study Contact 2',
      NAME: {
        LABEL: 'Full Name',
        NAME: 'contact1_name',
        NAME_2: 'contact2_name',
        DESCRIPTION: 'Max character limit is 250.',
        LIMIT: 250,
      },
      POSITION: {
        LABEL: 'Position/Title',
        NAME: 'contact1_position',
        NAME_2: 'contact2_position',
        PLACEHOLDER: 'Choose a position/title',
      },
      EMAIL: {
        LABEL: 'Email',
        NAME: 'contact1_email',
        NAME_2: 'contact2_email',
        PLACEHOLDER: 'e.g. name@mail.com',
      },
      PHONE: {
        LABEL: 'Phone Number',
        NAME: 'contact1_phone',
        NAME_2: 'contact2_phone',
        PLACEHOLDER: 'e.g. 6045555555',
      },
      EXTENSION: {
        LABEL: 'Extension',
        NAME: 'contact1_phone_extension',
        NAME_2: 'contact2_phone_extension',
      },
    },
  },

  STUDY_COMPLETED: {
    INPUTS: {
      COMPLETED: {
        TITLE: 'Is this study completed?',
        LABEL: 'Yes, this study is completed.',
        NAME: 'is_completed',
      },
      SUMMARY: {
        LABEL: 'Summary of Results',
        DESCRIPTION: 'In a brief paragraph, summarize the results of the research study in lay language. If a clinical trial, provide results related to primary and secondary endpoints (in neutral, unbiased language), side effects, next steps (e.g. another study, publication, licensing, what this means for people who live with the condition studied e.g. clinical care, etc.) (Max character limit is 900).',
        COUNT: 900,
        NAME: 'result_summary',
      },
      LIMITATIONS: {
        LABEL: 'Study Limitations',
        DESCRIPTION: 'In 3-4 sentences, describe any limitations to the study.',
        DESCRIPTION_SECONDARY: 'For example, any populations that were excluded from the study, other limitations and how these may be addressed in the future. (Max character limit is 450)',
        COUNT: 450,
        NAME: 'study_limitations',
      },
      ENGAGED: {
        LABEL: 'Number of Patient Partners engaged',
        DESCRIPTION: 'How many patient partners were engaged on your team?',
        NAME: 'patient_partners_engagement_number',
      },
      ENGAGEMENT_METHOD: {
        LABEL: 'How were Patient Partners engaged',
        DESCRIPTION: 'Briefly explain in 5-6 sentences how patient partners were engaged?',
        DESCRIPTION_SECONDARY: 'Example: Patient partners helped design and carry out the research questions, communicating the study findings by recommending ways and places to share the results, and helped develop reports for stakeholders. (Max character limit is 900).',
        NAME: 'patient_partners_engagement_method',
        COUNT: 900,
      },
      ONLINE: {
        TITLE: 'Is this study an online or remote study? ',
        TITLE_OPPORTUNTIY: 'Is this opportunity an online or remote opportunity?',

        DESCRIPTION: 'Example: No in-person participation?',
        LABEL: 'Yes, this is an online or remote study.',
        NAME: 'is_online',
      },
      HOSPITAL: {
        LABEL: 'Hospital or Institution',
        NAME: 'hospital_id',
        DESCRIPTION: 'Please enter the address of the primary physical location that you would like shared in the posting.',
        PLACEHOLDER: 'Choose a hospital or institution',
      },
      BUILDING: {
        LABEL: 'Building',
        NAME: 'building',
        DESCRIPTION: 'Please enter the address of the primary physical location that you would like shared in the posting. (Max character limit is 120)',
        COUNT: 120,
      },
      DEPARTMENT: {
        LABEL: 'Department',
        DESCRIPTION: 'Please enter the address of the primary physical location that you would like shared in the posting. (Max character limit is 120)',
        NAME: 'department',
        COUNT: 120,
      },
      STREET: {
        LABEL: 'Street Address',
        NAME: 'street',
        DESCRIPTION: 'Please enter the address of the primary physical location that you would like shared in the posting. (Max character limit is 120)',
        COUNT: 120,
      },
      CITY: {
        LABEL: 'City',
        NAME: 'city',
        DESCRIPTION: 'Please enter the address of the primary physical location that you would like shared in the posting. (Max character limit is 120)',
        COUNT: 120,
      },
      POSTAL_CODE: {
        LABEL: 'Postal Code',
        NAME: 'postal_code',
        DESCRIPTION: 'Please enter the address of the primary physical location that you would like shared in the posting.',
      },
    },
  },

  INFORMATION: {
    TITLE: 'Additional Information/Resources',
    DESCRIPTION: 'Share information about the outcomes or impacts of the project here. This might include knowledge translation products such as links to your organization, project websites or videos, publications, or tools that you have created.',
    INPUTS: {
      WEBSITE_URL: {
        LABEL: 'Weblink URL',
        DESCRIPTION: 'Do you have a website or video that describes the results of your opportunity? Paste the link below.',
        PLACEHOLDER: 'i.e. https://www.example.com',
        NAME: 'resource_website_url',
      },
      WEBSITE_NAME: {
        LABEL: 'Name of website/linked resource',
        DESCRIPTION: 'This text will be displayed on your Results page and will link to the website/resource. ',
        DESCRIPTION2: 'Example: BC SUPPORT Unit homepage',
        PLACEHOLDER: 'i.e. https://www.example.com',
        NAME: 'resource_website_name',
        COUNT: 256,
      },
      DOCUMENT: {
        LABEL: 'Document Upload',
        DESCRIPTION: 'Pdf file only. Maximum file size 15MB.',
        NAME: 'resource_file',
      },
    },
  },

  COMMENTS: {
    INPUTS: {
      COMMENT: {
        LABEL: 'Comments',
        DESCRIPTION: 'List any additional information to assist the REACH BC Office with approving your results.',
        DESCRIPTION_SECONDARY: 'Example: Study results are complete or results have been updated to include a new resource/document available.',
        NAME: 'comments',
      },
    },
  },

  ADMIN_COMMENTS: {
    INPUTS: {
      COMMENT: {
        LABEL: 'Admin Comments',
        NAME: 'admin_comments',
      },
    },
  },
};
