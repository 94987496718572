export const RESULTS_TYPE_MODAL = {
  TITLE: 'Add Study Result',
  LABEL: 'Select a type of your study result',
  OPTIONS: [
    {
      label: 'Result of existing research study on REACH BC',
      value: 'study',
    },
    {
      label: 'Result of a standalone research study',
      value: 'standalone',
    },
  ],

  BUTTONS: {
    CANCEL: 'Cancel',
    CONTINUE: 'Continue',
  },
};
