import React from 'react';
import { Table } from '../../../components/Table';
import { columnsResearcher, columnsSiteVolunteer } from '../../../utils/researcherConst';
import * as Styled from './styles';
import { RESEARCHER_DASHBOARD, TABS } from './const';
import { Tabs, TextInput } from '../../../components';

type ResearcherDashboardProps = {
  tableData: any[]
  hasSiteCode?: boolean
  currentTab: { label: string, value: string }
  siteVolunteerData: any[]
  setCurrentTab: (tab: { label: string, value: string }) => void
  searchValue: string
  searchHandler: (searchValue: string) => void
  isLoading?: boolean
}

const ResearcherDashboard = ({
  tableData,
  hasSiteCode = false,
  currentTab,
  setCurrentTab,
  siteVolunteerData,
  searchHandler,
  searchValue,
  isLoading = false,
}: ResearcherDashboardProps) => (
  <Styled.TableWrapper>
    {hasSiteCode && (
      <Tabs
        tabs={RESEARCHER_DASHBOARD.TABS}
        selectTabHandler={setCurrentTab}
        selectedTab={currentTab}
      />
    )}

    {currentTab.value === TABS.OPPORTUNITIES && (
      <>
        <Styled.InputWrapper>
          <TextInput
            setTextValue={searchHandler}
            textValue={searchValue}
            placeholder={RESEARCHER_DASHBOARD.SEARCH_BAR.PLACEHOLDER}
            theme="searchSecondary"
            isSearchBar
          />
        </Styled.InputWrapper>
        <Table
          columnHeaders={columnsResearcher}
          tableData={tableData}
          emptyStateMessage={RESEARCHER_DASHBOARD.EMPTY_STATE}
          isLoading={isLoading}
        />
      </>
    )}
    {currentTab.value === TABS.SITE_VOLUNTEERS && !isLoading && (
      <Table
        columnHeaders={columnsSiteVolunteer}
        tableData={siteVolunteerData}
        tableType="site_volunteer_table"
      />
    )}
  </Styled.TableWrapper>
);

export default ResearcherDashboard;
